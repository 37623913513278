import React, { useState } from 'react';
import { Typography, Grid, CardMedia, Dialog, Container } from '@mui/material';
import { styled } from '@mui/system';

import img01 from './assets/img01.jpg'; 
import img02 from './assets/img02.jpg';
import img03 from './assets/img03.jpg';
import img04 from './assets/img04.jpg';
import img05 from './assets/img05.jpg';
import img06 from './assets/img06.jpg';
import img07 from './assets/img07.jpg';
import img08 from './assets/img08.jpg';
import img09 from './assets/img09.jpg';
import img10 from './assets/img10.jpg';
import img11 from './assets/img11.jpg';
import img12 from './assets/img12.jpg';

const images = [
  { src: img01, alt: 'Image 1', text: '' },
  { src: img02, alt: 'Image 2', text: '' },
  { src: img03, alt: 'Image 3', text: '' },
  { src: img04, alt: 'Image 4', text: '' },
  { src: img05, alt: 'Image 5', text: '' },
  { src: img06, alt: 'Image 6', text: '' },
  { src: img07, alt: 'Image 7', text: '' },
  { src: img08, alt: 'Image 8', text: '' },
  { src: img09, alt: 'Image 9', text: '' },
  { src: img10, alt: 'Image 10', text: '' },
  { src: img11, alt: 'Image 11', text: '' },
  { src: img12, alt: 'Image 12', text: '' },
 
  // add more images as needed
];
const StyledTypography = styled(Typography)(({ theme }) => ({
  margin: '20px 20px',
  fontSize: '1.5em',
  lineHeight: '1.4',
  color: theme.palette.primary.main,
}));

const StyledCardMedia = styled(CardMedia)({
  paddingTop: '56.25%',
});

const StyledRoot = styled('div')(({ theme }) => ({
  marginTop: '100px',
  // ... (add custom styling properties as needed)
}));

const StyledGridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '-50px', // Adjust margin as needed
});

const StyledImagesContainer = styled(Container)({
  marginBottom: '20px', // Add margin to create a gap before the footer
  paddingBottom: '200px', // Add padding at the bottom to create a gap
});

function About() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const handleImageClick = (image, index) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
  };

  const handleNextImage = () => {
    const nextIndex = (currentImageIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
    setCurrentImageIndex(nextIndex);
  };

  return (
    <StyledRoot>
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
            <StyledTypography variant="body1">
              Constantia MediSuites is a private medical centre situated in a serene, secure location opposite Mediclinic Constantiaberg. Our doctors and health practitioners are all established in their respective fields and value relationships with new and long-term patients.
            </StyledTypography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTypography variant="body1">
              The MediSuites offer a peaceful environment with an open, light feel and modern amenities. There is a tranquil garden and the property is child- and wheelchair friendly with ample on-site parking.
              Our reception and support staff look forward to welcoming you.
            </StyledTypography>
          </Grid>
        </Grid>
      </Container>

      {/* Add padding at the bottom to create a gap between the images and the footer */}
      <StyledImagesContainer maxWidth="md">
        <Grid container spacing={3}>
          {images.map((image, index) => (
            <StyledGridItem item xs={6} sm={6} md={3} key={index}>
              <StyledCardMedia
                component="img"
                image={image.src}
                alt={image.alt}
                onClick={() => handleImageClick(image, index)}
              />
              <Typography variant="body2">{image.text}</Typography>
            </StyledGridItem>
          ))}
        </Grid>
      </StyledImagesContainer>

      <Dialog open={selectedImage !== null} onClose={() => setSelectedImage(null)}>
        {selectedImage && (
          <img
            src={selectedImage.src}
            alt={selectedImage.alt}
            style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
            onClick={handleNextImage}
          />
        )}
      </Dialog>
    </StyledRoot>
  );
}

export default About;