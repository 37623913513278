import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const iconStyle = {
  width: 40,
  height: 30,
  display: 'none', //change to 'flex' when socials are setup
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'primary',
  marginRight: '20px',
  color: 'secondary.main',
  '&:hover': {
    color: 'primary.main',
  },
};

function AppFooter() {
  const theme = useTheme();
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(false);
  const [mouseOverFooter, setMouseOverFooter] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      // Always show the footer on the landing page
      setShowFooter(true);
    } else {
      // Show the footer when scrolling on other pages
      const handleScroll = () => {
        if (
          window.innerHeight + window.scrollY >= document.body.offsetHeight - 10
        ) {
          setShowFooter(true);
        } else if (!mouseOverFooter) {
          setShowFooter(false);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [location.pathname, mouseOverFooter]);

  useEffect(() => {
    if (!showFooter) {
      // Hide footer after 3 seconds if the mouse is not over it
      const hideFooterTimeout = setTimeout(() => {
        if (!mouseOverFooter) {
          setShowFooter(false);
        }
      }, 3000);

      return () => {
        clearTimeout(hideFooterTimeout);
      };
    }
  }, [showFooter, mouseOverFooter]);

  const handleMouseEnter = () => {
    setMouseOverFooter(true);
  };

  const handleMouseLeave = () => {
    setMouseOverFooter(false);
  };

  return (
    <Typography
      component="footer"
      sx={{
        display: showFooter ? 'flex' : 'none',
        bgcolor: theme.palette.primary.main,
        color: '#ffffff', // Change the color of the "CMS" text to white
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        '& a': {
          color: '#ffffff', // Change the color of the link to white
          textDecoration: 'none', // Remove underline from the link
        },
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      id="app-footer"
    >
      <Container sx={{ my: 1, display: 'flex' }}>
        <Box sx={{ display: 'flex' }}>
          <Box component="a" href="https://www.facebook.com/yourpage" sx={iconStyle}>
            <FacebookIcon />
          </Box>
          <Box component="a" href="https://twitter.com/yourpage" sx={iconStyle}>
            <TwitterIcon />
          </Box>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Typography variant="body2">
            © <a href="https://www.constantiamed.co.za">CMS</a> {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Typography>
  );
}

export default AppFooter;
