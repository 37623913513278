import React, { useState } from 'react';
import { AppBar, Toolbar, styled, Box, Button, Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './assets/CMSLogo.png';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Add this line to import useTheme

const StyledAppBar = styled(AppBar)({
  zIndex: 9999, // Ensure the AppBar is displayed on top
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: '80px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-end',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: 'white',
  border: '1px solid white',
  marginLeft: '20px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const StyledLogo = styled('img')({
  maxHeight: '100px', // You can change this to your desired height
  height: '100px', // Add a fixed height to the logo
  objectFit: 'contain', // Maintain aspect ratio and fit within the specified height
  marginRight: 'auto',
  padding: '5px',
});


const StyledMobileMenuButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

function NavBar() {
  const [isMenuOpen, setMenuOpen] = useState(null);
  const theme = useTheme(); // Add this line to get the theme
  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  return (
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <StyledLogo src={logo} alt="Logo" />
        <div>
          <StyledButton component={Link} to="/">Home</StyledButton>
          <StyledButton component={Link} to="/about">About</StyledButton>
          <StyledButton component={Link} to="/services">Services</StyledButton>
          <StyledButton component={Link} to="/contact">Contact</StyledButton>
          {/* Mobile Menu Button */}
          <StyledMobileMenuButton onClick={handleMenuOpen} color="inherit">
            <MenuIcon />
          </StyledMobileMenuButton>
          <Menu
          anchorEl={isMenuOpen}
          open={Boolean(isMenuOpen)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              backgroundColor: theme.palette.primary.main, // Use the primary color from the theme
              color: 'white', // Set the text color to white
              width: '200px',
            },
            }}
          >
            <MenuItem onClick={handleMenuClose} component={Link} to="/">
              Home
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/about">
              About
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/services">
              Services
            </MenuItem>
            <MenuItem onClick={handleMenuClose} component={Link} to="/contact">
              Contact
            </MenuItem>
          </Menu>
        </div>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default NavBar;
