import React from 'react';
import { Container, Typography, List, ListItem, ListItemIcon, ListItemText, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const officeHours = [
  { id: 1, icon: <AccessTimeIcon />, text: 'Monday to Friday: 08h30 - 18h00' },
  { id: 2, icon: <AccessTimeIcon />, text: 'Saturday: 08h30 - 13h00' },
  { id: 3, icon: <AccessTimeIcon />, text: 'Sunday & Public Holidays: Closed' },
];

const contactInfo = [
  { id: 1, icon: <HomeIcon />, text: '62 Burnham Road, Plumstead (opposite Mediclinic Constantiaberg) ' },
  { id: 2, icon: <PhoneIcon />, text: '(021) 001 1190' },
  { id: 3, icon: <EmailIcon />, text: 'reception@constantiamed.co.za' },
];

export default function Contact() {
  const theme = useTheme();

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" align="center" gutterBottom>
            Contact Us
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box 
            sx={{ 
              width: '100%', 
              padding: '20px',
              marginBottom: '40px', 
              boxShadow: '0px 10px 15px rgba(0,0,0,0.1)', 
              borderRadius: '15px'
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, marginTop: '0px' }}>
              Office Hours
            </Typography>
            <List>
              {officeHours.map((item) => (
                <ListItem key={item.id}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText>
                    {item.text}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box 
            sx={{ 
              width: '100%', 
              padding: '20px',
              marginBottom: '40px', 
              boxShadow: '0px 10px 15px rgba(0,0,0,0.1)', 
              borderRadius: '15px'
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, marginTop: '0px' }}>
              Contact Details
            </Typography>
            <List>
              {contactInfo.map((item) => (
                <ListItem key={item.id}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  {item.text === 'reception@constantiamed.co.za' ? (
                    <ListItemText>
                      <a href={`mailto:${item.text}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {item.text}
                      </a>
                    </ListItemText>
                  ) : (
                    <ListItemText>
                      {item.text}
                    </ListItemText>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box 
            sx={{ 
              width: '100%', 
              height: '600px', 
              marginBottom: '40px', 
              boxShadow: '0px 10px 15px rgba(0,0,0,0.1)', 
              borderRadius: '15px', 
              overflow: 'hidden',
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center' 
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6613.716050687659!2d18.449455348513407!3d-34.021855078021034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc43520b5c88e9%3A0x61807c9ddfbcd80e!2sConstantia%20Medisuites!5e0!3m2!1sen!2sza!4v1692096505029!5m2!1sen!2sza" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
