import React from 'react';
import { Typography, Container, Grid, Box, useTheme } from '@mui/material';
import bgImage from './assets/landingPic2.jpg';

function Landing() {
  const theme = useTheme();

  const styles = {
    box: {
      backgroundColor: 'rgba(40, 40, 40, 0.71)', 
      padding: '5%',
      borderRadius: '8px',
      marginBottom: '0px', // Move the box down by 20px
    },
    heading: {
      color: theme.palette.secondary.add,
      fontSize: '3em',
      fontWeight: 'bold',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
      textAlign: 'center',
    },
    paragraph: {
      color: theme.palette.secondary.add,
      fontSize: '1.5em',
    
      fontWeight: 'bold',
      textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
      textAlign: 'center', // Center align the paragraph
    },
  };
  

  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="lg" style={{ paddingTop: '600px' }}> {/* Adjust the paddingTop value */}
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Box style={styles.box}>
              <Typography variant="h1" style={styles.heading}>
                Welcome to Constantia MediSuites
              </Typography>
              <Typography variant="body1" style={styles.paragraph}>
                Specialised care for a healthier tomorrow
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Landing;
