import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import Landing from './Landing';
import NavBar from './NavBar';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import AppFooter from './AppFooter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { color, styled } from '@mui/system';
import CookiesPopup from './CookiesPopup';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0e546d',
      add: '#363636',
    },
    secondary: {
      main: '#16b28b',
      add: 'rgb(255, 255, 255)',
    },
  },
});

const StyledRoot = styled(Box)(({ theme }) => ({
  background: color(theme.palette.secondary.main),
  minHeight: '100vh',
  overflowY: 'scroll', // Add this line to enable scrolling
}));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavBar />
        <StyledRoot>
          <CookiesPopup />
          <Box component="main" flex="1">
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              {/* Add more Routes as needed */}
            </Routes>
            <AppFooter /> {/* Move AppFooter inside the Box component */}
          </Box>
        </StyledRoot>
      </Router>
    </ThemeProvider>
  );
}

export default App;