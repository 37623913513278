import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function CookiesPopup() {
  const [open, setOpen] = useState(true);

  const handleAccept = () => {
    // Handle the user's acceptance of cookies here
    setOpen(false);
  };

  const handleDecline = () => {
    // Handle the user's decline of cookies here
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDecline} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{"Cookies Usage"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This website uses cookies to ensure you get the best experience on our website.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} color="primary">
          Accept
        </Button>
        <Button onClick={handleDecline} color="primary" autoFocus>
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CookiesPopup;
