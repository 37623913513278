import React, { useState } from 'react';
import { Typography, Container, Box, Button, Avatar, Modal, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import room01 from './assets/room01.jpg';
import room02 from './assets/room02.jpg';
import room03 from './assets/room03.jpg';
import room04 from './assets/room04.jpg';
import room05 from './assets/room05.jpg';
import servicesBg from './assets/services_bg.jpg'; 


const doctors = [
  {
    name: 'Dr Nadia de Villiers',
    specialization: 'Allergy & Paediatric GP',
    description:
      'Dr Nadia’s practice is known for her kind, compassionate nature and thorough assessment. She treats children of all ages for general health-related problems as well as child & adult allergic conditions.\n\nApart from managing conditions related to allergies, she has extensive experience in general childhood illnesses, complicated paediatric cases, developmental and behavioral screening, paediatric emergencies, as well as neonatal high risk and intensive care.',
    image: room01,
    website: 'https://drnadiadevilliers.co.za/',
    bookingEmail: 'reception@constantiamed.co.za',
  },
  {
    name: 'Malcolm Freedman',
    specialization: 'Orthotist & Prosthetist',
    description:
      'Malcolm specialises in lower limb biomechanical gait analysis, orthotic solutions for osteoarthritis of the knee, sport injuries and rehabilitation, as well as conservative management of scoliosis and acute spinal injuries.\n\nHis patients have immediate access to the very best in sports, medical and lifestyle support products. Assessment, casting and manufacturing of prosthetic devices is done in his lab where he works, together with the amputees, to achieve their goals. Malcolm’s passion is to preserve and improve the quality of life of all his patients.',
    image: room02,
    website: 'https://www.orthopro.co.za',
    bookingEmail: 'reception@constantiamed.co.za',
  },
  {
    name: 'Dr Amina Guffar',
    specialization: 'Women’s Health and Medical Aesthetics',
    description:
      'Dr Amina is a medical practitioner with a special interest in Female Health and Aesthetic Medicine. Her consultation incorporates evidence-based modern medicine along with integrative approaches.\n\nThe aim of her practice is to empower and enhance the lives of women through beauty and overall wellbeing -physically, mentally & aesthetically.',
    image: room03,
    website: 'https://www.draminaguffar.co.za',
    bookingEmail: 'reception@constantiamed.co.za',
  },
  
  {
    name: 'IV Medical Care',
    specialization: 'Dr Yusuf Arieff: Vitamin & Iron Infusion',
    description:
      'Dr Yusuf Arieff is a caring and highly-experienced General Practitioner with a focus on IV vitamin and iron infusions tailored to individual needs. He’s realised the great impact iron and vitamins have to a better quality, stress-free and energetic life. With experience in iron infusions, he ventured into unique formulations of vitamin infusions which are exclusively available at Constantia MediSuites, including the pioneering Anti-Aging NAD Infusion. \n\nDr Arieff has successfully tailored infusions for women in all stages of life, sports enthusiasts, and individuals with different health and wellness goals. Iron infusions are safely given without having the need of being admitted to a hospital. He’ll also be offering biologics in the near future.',
    image: room05,
    website: null,
    bookingEmail: 'reception@constantiamed.co.za',
  },
  {
    name: 'Tracy Ugarchund & Associate Dietitians',
    specialization: 'Registered Dietitian',
    description:
      'Tracy Ugarchund has many years of experience in almost all aspects of nutrition and diet including weight management, diabetes, oncology and critical care. She is driven to help people see that food can be enjoyed even when it’s healthy.\n\nHer tailored treatment plans aim to empower her clients with the ability to plan, prepare and cook their own healthy food and help overcome chronic lifestyle diseases. She helps clients establish personalised dietary & health goals, while respecting cultural, budget & lifestyle needs for holistic nutrition.',
    image: room04,  
    website: 'https://tracyugarchund.co.za',
    bookingEmail: 'reception@constantiamed.co.za',
  },
 
  // Other doctors...
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh', // Set a maximum height for the modal content
  overflowY: 'auto', // Enable vertical scrolling if content exceeds the height
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ... (existing code)

const DoctorCard = ({ doctor }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={2}>
      <Avatar alt={doctor.name} src={doctor.image} sx={{ width: 100, height: 100 }} />
      <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>{doctor.name}</Typography>
      <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }}>
        {doctor.specialization}
      </Typography>
      <Button variant="outlined" onClick={handleOpen} sx={{ marginTop: '10px', color: theme.palette.primary.main }}>
        More Info
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, overflowY: 'scroll' }}>
          <Typography variant="h4" component="h2" sx={{ color: theme.palette.text.primary }}>{doctor.name}</Typography>
          <Typography variant="subtitle1" sx={{ color: theme.palette.primary.main }}>
            {doctor.specialization}
          </Typography>
          <Box mt={2} mb={3}>
            <Avatar alt={doctor.name} src={doctor.image} sx={{ width: 200, height: 200 }} />
          </Box>
          <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
            {doctor.description.split('\n\n').map((paragraph, index) => (
              <Box key={index} mb={2}>
                {paragraph}
              </Box>
            ))}
          </Typography>
          {doctor.website && (
            <Box mt={2}>
              <Button href={doctor.website} variant="contained" target="_blank" rel="noopener" color="primary">
                Visit Website
              </Button>
            </Box>
          )}
          <Box mt={2}>
            <Button href={`mailto:${doctor.bookingEmail}`} variant="contained" color="primary">
              Book Appointment
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};




export default function Services() {
  const theme = useTheme();

  return (
    

    <Container
      maxWidth="xl"
      sx={{
        minHeight: '100vh', // Make the container fill the entire viewport
        minWidth: '100vw',
        paddingTop: '100px',
        paddingBottom: '50px',
        backgroundImage: `url(${servicesBg})`, // Apply the background image
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >


      {/* Small Container for Top Typography */}
      <Container maxWidth="md" sx={{ marginBottom: '111px' }}>
        <Box my={5} textAlign="center">
          <Typography variant="body1" sx={{ color: theme.palette.primary.main, fontSize: '24px' }}>
            Here at Constantia MediSuites, we pride ourselves on excellent service and medical treatment that build trust and keeps up to date with current medical research and health trends.
          </Typography>
        </Box>
      </Container>

      {/* The Doctors Section */}
      <Typography variant="h3" align="center" gutterBottom sx={{ color: theme.palette.primary.main, marginTop: '40px' }}>
        Services
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {doctors.map((doctor, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} xl={2} key={index}>
            <DoctorCard doctor={doctor} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}